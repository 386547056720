<script>
  import Button from '../components/Button.svelte';
  import { general } from '../resources/strings';
  import { resetAllErrors } from '../stores/error.store';
  import { resetSelectedBank } from '../stores/selected-bank.store';
  import { resetState } from '../stores/state.store';
  import { stopProgress } from '../stores/transaction-progress.store';

  function handleRetry() {
    resetAllErrors();
    resetSelectedBank();
    resetState();
    stopProgress();
  }
</script>

<Button on:click={handleRetry}>{general.retryTrx}</Button>

import { errors, errorOverwrite, hiddenErrors } from './error-map';

function getError(errorKey) {
  if (typeof errorKey === 'string' && isKnownError(errorKey)) {
    return errors[errorKey];
  }
  return errors.Unknown;
}

function isKnownError(errorKey) {
  return Object.keys(errors).includes(errorKey);
}

function getOverwrittenErrorName(errorKey) {
  return errorOverwrite[errorKey] ?? errorKey;
}

function hideErrorCode(error, role) {
  if (!error) {
    return '';
  }

  const errorCode = getErrorCode(error, role);

  return errorCode;
}

function getErrorCode(error, role) {
  if (hiddenErrors[role]) {
    return (
      hiddenErrors[role].find(({ errorCode }) => errorCode === error)
        ?.overWriteError ?? error
    );
  }

  return error;
}

export { isKnownError, getError, getOverwrittenErrorName, hideErrorCode };
